//* mixins/useCookies.js

const checkCookie = function(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  return parts.length === 2;
};

const setCookie = function(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = function(name) {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
};

const deleteCookie = function(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
};

const useCookies = controller => {
  Object.assign(controller, {
    checkCookie,
    setCookie,
    getCookie,
    deleteCookie,
  });
};

export { checkCookie, setCookie, getCookie, deleteCookie, useCookies };

export default useCookies;
