import ApplicationController from "../../../application_controller.js";
import ahoy from "ahoy.js";

if(process.env.NODE_ENV !== "production"){
  window.ahoy = ahoy;
}

export default class extends ApplicationController {
  connect() {
    this.log_function("connect");
    ahoy.configure({
      // ? These must match the route defined within the application
      visitsUrl: `/${window._tracking_path}/visits`,
      eventsUrl: `/${window._tracking_path}/events`,
      trackVisits: true,
    });


    this.boundAnalyticsTrackEvent = this.onAnalyticsTrackEvent.bind(this);
    window.addEventListener("ahoy_trackEvent", this.boundAnalyticsTrackEvent);

    this.defaultValues = {
      business: this.businessName,
      id: parseInt(this.businessId),
      source: this.source,
      locale: this.locale,
    };

    ahoy.trackView(this.defaultValues);
    // ahoy.trackClicks("a, button, input[type=submit]");
    // ahoy.trackChanges("input, textarea, select");
  }

  onAnalyticsTrackEvent(event){
    this.log_function("onAnalyticsTrackEvent", event);

    if(typeof event.detail === "undefined"){
      this.log_error_function("onAnalyticsTrackEvent", "analytics", "No tracking details provided.");
      return false;
    }

    // ? saves data from event details into variable and rejects null values
    let trackEventVariables = Object.fromEntries(Object.entries(event.detail).filter(([key, value]) => value !== null));

    if(typeof trackEventVariables.type === "undefined"){
      this.log_error_function("onAnalyticsTrackEvent", "analytics", "No type value defined.");
      return false;
    }

    let eventType = trackEventVariables.type;
    delete trackEventVariables.type;

    ahoy.track(eventType, {...trackEventVariables, ...this.defaultValues});
  }

  disconnect(){
    this.log_function("disconnect");
  }

  get businessId() {
    const businessId = document.querySelector("meta[property='business-id']");
    return businessId ? businessId.content : "unknown";
  }

  get businessName() {
    const businessName = document.querySelector("meta[property='business-name']");
    return businessName ? businessName.content : "unknown";
  }

  get source() {
    const source = document.querySelector("meta[name='source']");
    return source ? source.content : "unknown";
  }

  get locale() {
    const locale = document.querySelector("meta[name='current-locale']");
    return locale ? locale.content : "unknown";
  }
}
