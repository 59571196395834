import ApplicationController from "../application_controller.js";
import { useDebounce } from "stimulus-use";
import Headroom from "headroom.js";

export default class extends ApplicationController  {
  static debounces = ["setBodyPadding"];

  connect() {
    this.log_function("connect");

    useDebounce(this);
    this.element.classList.add("headroom", "sticky-top");

    this.headroomInstance = new Headroom(this.element, {
      offset: this.element.offsetHeight * 2,
    });

    document.documentElement.style.setProperty("--gt-header-height", this.height);

    this.headroomInstance.init();
  }

  disconnect() {
    this.log_function("disconnect");

    //* Fix Javascript Error
    //* Cannot read properties of undefined (reading 'destroy')
    //* https://github.com/WickyNilliams/headroom.js/issues/367#issuecomment-752256742
    // HACK Remove this when plugin dev resolve problem
    if (!this.headroomInstance.scrollTracker) {
      this.headroomInstance.scrollTracker = {
        destroy: function() {},
      };
    }

    this.headroomInstance.destroy();
  }

  get height(){
    return `${this.element.offsetHeight}px`;
  }
}
