import { I18n } from "i18n-js";

//* Fetch user locale from html#lang.
//* This value is being set in the template layout.
//* is inferred from `ACCEPT-LANGUAGE` header.
const userLocale = document.documentElement.lang;

export const i18n = new I18n();
i18n.defaultLocale = "en";
i18n.enableFallback = true;
i18n.locale = userLocale;
