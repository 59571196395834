/* global Stimulus */
console.log("template0/essentials.js Loaded");

import "@hotwired/turbo-rails";
// require("@rails/activestorage").start();
// require("local-time").start();

//* Main application
import "../../controllers/application";

//* i18n
import { i18n } from "../../config/i18n";
window.i18n = i18n;

window.Turbo.setProgressBarDelay(1000); //* Time in ms 1000ms = 1s

//* Lazysizes
import "lazysizes";

//* Stimulus
import header from "../../view_components/header/index.js";
Stimulus.register("header", header);

import BootstrapModal from "../../view_components/modal/bootstrap/index.js";
Stimulus.register("modal--bootstrap", BootstrapModal);

import print_detect from "../../view_components/print_detect/index.js";
Stimulus.register("print-detect", print_detect);

import popover from "../../view_components/popover/index.js";
Stimulus.register("popover", popover);

import swiper from "../../view_components/swiper/index.js";
Stimulus.register("swiper", swiper);

import development_tools from "../../view_components/development_tools/index.js";
Stimulus.register("development-tools", development_tools);

import analyticsGoogleInitializer from "../../view_components/analytics/google/initializer/index.js";
Stimulus.register("analytics--google--initializer", analyticsGoogleInitializer);

import analyticsGoogleEvent from "../../view_components/analytics/google/event/index.js";
Stimulus.register("analytics--google--event", analyticsGoogleEvent);

import analyticsInitializer from "../../view_components/analytics/ahoy/initializer/index.js";
Stimulus.register("analytics--initializer", analyticsInitializer);

import analyticsEvent from "../../view_components/analytics/ahoy/event/index.js";
Stimulus.register("analytics", analyticsEvent);

import noscript_cleanup from "../../view_components/noscript_cleanup/index.js";
Stimulus.register("noscript-cleanup", noscript_cleanup);

import locale_informer from "../../view_components/locale_informer/index.js";
Stimulus.register("locale-informer", locale_informer);
