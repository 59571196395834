import ApplicationController from "../application_controller.js";
import Popover from "bootstrap/js/dist/popover";

export default class extends ApplicationController  {
  static values = {
    title: { type: String, default: "" },
    placement: { type: String, default: "top" },
    custom_class: { type: String, default: "" },
    offset: { type: String, default: "0,0" },
    container: { type: String, default: "body" },
    trigger_on: { type: String, default: "hover focus" },
  };
  static targets = [ "content" ];

  connect() {
    this.log_function("connect");

    this.popover = new Popover(this.element, {
      html: true,
      container: this.containerValue,
      content: this.contentTarget.innerHTML,
      title: this.titleValue,
      placement: this.placementValue,
      customClass: this.customClassValue,
      offset: this.offsetValue,
      trigger: this.triggerOnValue,
    });
  }

  disconnect(){
    this.log_function("disconnect");

    this.popover.dispose();
  }
}
