import ApplicationController from "../application_controller.js";
import Toast from "bootstrap/js/dist/toast";
import { useCookies } from "mixins";

export default class extends ApplicationController {
  static values = {
    pageName: { type: String, default: "" },
    detectedLocale: String,
    cookieExpiration: { type: Number, default: 0 },
  };

  connect() {
    this.log_function("connect");

    useCookies(this);

    this.toastInstance = Toast.getOrCreateInstance(this.element, { animation: false, autohide: false, delay: 10000 });

    if(!this.checkCookie("preferredLocale")){
      this.toastInstance.show();
      this.dispatch("ahoy_trackEvent", { detail: { type: "ux", location: "locale_informer", action: "displayed notification", page: this.pageNameValue }, prefix: false });
    }
  }

  close(event) {
    this.log_function("close");

    const preferredLocale = event.params.preferredLocale;
    this.setCookie("preferredLocale", preferredLocale, this.cookieExpirationValue);
    this.toastInstance.hide();
  }

  disconnect(){
    this.log_function("disconnect");

    this.toastInstance.dispose();
  }

  get applicationLocale() {
    this.log_function("applicationLocale");

    return document.querySelector("meta[name='current-locale']").content;
  }
}
