import ApplicationController from "../application_controller.js";
import { Swiper } from "swiper";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { useSwiperConfig } from "./useSwiperConfig";
import mergeWith from "lodash/mergeWith";

export default class extends ApplicationController  {
  static targets = [ "container", "slide", "loader", "pagination", "buttonPrev", "buttonNext", "scrollbar" ];
  static values = {
    delay: { type: Number, default: 0 },
    configuration: { type: String, default: "default" },
    navigation: { type: Boolean, default: false },
    scrollbar: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },
  };

  initialize(){
    this.log_function("initialize");

    this.swiper = false;

    //* Hide the content if there is a loader defined
    if(this.hasLoaderTarget){
      this.containerTarget.style.display = "none";
    }
  }

  connect() {
    this.log_function("connect");

    useSwiperConfig(this);

    setTimeout(() => {
      this.initializeSwiper();
    }, this.delayValue);
  }

  initializeSwiper(){
    this.log_function("initializeSwiper");

    this.swiper = new Swiper(this.containerTarget, this.config);

    //* Hide the loader and show the content after load
    if (this.hasLoaderTarget) {
      this.containerTarget.style.display = "";
      this.loaderTarget.style.display = "none";
    }
  }

  get slideCount(){
    this.log_function("get slideCount");

    return this.slideTargets.length;
  }

  get config(){
    this.log_function("get config");

    let config = {
      lazy: this.lazyValue,
      modules: [],
      grabCursor: true,
    };

    if(this.navigationValue){
      config["modules"].push(Navigation);
      config["navigation"] = {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      };
    }

    if (this.scrollbarValue) {
      config["modules"].push(Scrollbar);
      config["scrollbar"] = {
        el: this.scrollbarTarget,
        draggable: true,
      };
    }

    if (this.paginationValue) {
      config["modules"].push(Pagination);
      config["pagination"] = {
        el: this.paginationTarget,
        clickable: true,
      };
    }

    if (this.configurationValue !== "default") {
      const profileName = `config_${this.configurationValue}`;
      const configurationProfile = this[profileName];

      if (typeof configurationProfile !== "object") {
        this.log_error_function("get config", `Missing profile ${profileName} in Swiper component`);
      }

      config = mergeWith(config, configurationProfile);
    }

    return config;
  }

  disconnect() {
    this.log_function("disconnect");

    if(this.swiper) this.swiper.destroy();
  }

}
