/* global gtag, dataLayer */
import ApplicationController from "../../../application_controller.js";
import { useAssetLoader } from "mixins";

export default class extends ApplicationController {
  static values = {
    accountId: String,
    debugMode: { type: Boolean, default: false },
  };

  initialize(){
    this.log_function("initialize");

    window.dataLayer = window.dataLayer || [];
    var gtag = window.gtag = function(){ dataLayer.push(arguments); };
    gtag("js", new Date());
  }

  connect() {
    this.log_function("connect");
    useAssetLoader(this);

    this.load_script(this.analyticsJavascriptUrl);

    let options = {};

    if(this.debugModeValue){
      // * must be set this way because setting to false does not disable debug mode
      // * https://support.google.com/analytics/answer/7201382?hl=en
      options["debug_mode"] = true;
    }

    gtag("config", this.accountIdValue, options);

    this.boundAnalyticsTrackEvent = this.onAnalyticsTrackEvent.bind(this);
    window.addEventListener("googleAnalytics_trackEvent", this.boundAnalyticsTrackEvent);

    this.boundTurboCleanup = this.turboCleanup.bind(this);
    window.addEventListener("turbo:before-cache", this.boundTurboCleanup);
  }

  turboCleanup(event) {
    this.log_function("turboCleanup", event);

    this.element.remove();
  }

  onAnalyticsTrackEvent(event){
    this.log_function("onAnalyticsTrackEvent", event);

    if(typeof event.detail === "undefined"){
      this.log_error_function("onAnalyticsTrackEvent", "analytics", "No tracking details provided.");
      return false;
    }

    //* saves data from event details into variable and rejects null values
    let trackEventVariables = Object.fromEntries(Object.entries(event.detail).filter(([key, value]) => value !== null));

    if(typeof trackEventVariables.type === "undefined"){
      this.log_error_function("onAnalyticsTrackEvent", "analytics", "No type value defined.");
      return false;
    }

    let eventType = trackEventVariables.type;
    delete trackEventVariables.type;

    gtag("event", eventType, trackEventVariables);
  }

  disconnect(){
    this.log_function("disconnect");

    window.removeEventListener("googleAnalytics_trackEvent", this.boundAnalyticsTrackEvent);
    window.removeEventListener("turbo:before-cache", this.boundTurboCleanup);
  }

  get analyticsJavascriptUrl(){
    this.log_function("get analyticsJavascriptUrl");

    return `//www.googletagmanager.com/gtag/js?id=${this.accountIdValue}`;
  }
}
