import ApplicationController from "../application_controller.js";

export default class extends ApplicationController  {
  static values = {
    page_name: { type: String, default: "" },
  };

  connect() {
    this.log_function("connect");

    this.bound_after_print = this.after_print.bind(this);
    window.addEventListener("afterprint", this.bound_after_print);
  }

  after_print(event) {
    this.log_function("after_print", event);

    this.dispatch("ahoy_trackEvent", { detail: { type: "ux", action: "printed page", page: this.pageNameValue }, prefix: false });
  }

  disconnect(){
    this.log_function("disconnect");

    window.removeEventListener("afterprint", this.bound_after_print);
  }
}
