import ApplicationController from "../application_controller.js";

export default class extends ApplicationController {
  connect() {
    this.log_function("connect");

    this.element.remove();
  }

  disconnect(){
    this.log_function("disconnect");
  }
}
