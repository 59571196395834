/* global Stimulus */
// FEATURE make a tool for acessing the dev links
// FEATURE make a tool (saver_loader_9000 aka. SL9000) to move favorite fonts between websites.
// FEATURE make tool to load dev css tools like screen size/orientation
import ApplicationController from "../application_controller.js";
import { useAssetLoader } from "mixins";

export default class extends ApplicationController {
  static outlets = [
    "color-picker-9000",
    "text-editor-9000",
    "nest-view-9000",
  ];

  static values = {
    containerPath: String,
    fonts: { type: String, default: "" },
    js: { type: String, default: "" },
    css: { type: String, default: "" },
  };

  connect() {
    this.log_function("connect");

    useAssetLoader(this);

    this.boundRemoveUnlockEventListener = this.removeUnlockEventListener.bind(this);
    this.boundUnlockTool = this.unlockTool.bind(this);

    if (this.is_open) {
      this.open();
      this.removeEventListenerTimeout = false;
    } else {
      //* Start cheking for key presses
      this.addUnlockEventListener();
      //* Set a time out to Lock the tool
      this.removeEventListenerTimeout = setTimeout(this.boundRemoveUnlockEventListener, 30000);
    }
  }

  toggleColorPicker(){
    this.log_function("toggleColorPicker");

    this.colorPicker9000Outlet.toggle();
  }


  open() {
    this.log_function("open");

    this.load_script(this.jsValue);
    this.load_style(this.cssValue);
    localStorage.setItem("development_tools_open", true);
    this.element.src = this.containerPathValue;

    this.heightObserver = new MutationObserver((mutations) => {
      this.fixHeight();
    });

    this.heightObserver.observe(this.element, { attributes: true, childList: true, subtree: true });
  }

  close() {
    this.log_function("close");

    this.colorPicker9000Outlet.close();
    this.textEditor9000Outlet.deactivate();
    this.nestView9000Outlet.deactivate();

    this.element.src = null;
    localStorage.removeItem("development_tools_open");
    this.element.replaceChildren();
    this.addUnlockEventListener();

    if (this.heightObserver) this.heightObserver.disconnect();
  }

  unlockTool(event) {
    this.log_function("unlockTool");

    if (event && event.key && event.key.toLowerCase) {
      this.log_function("unlockTool", event.key);

      this.unlockPattern = ["g", "o", "t", "h", "a", "s", "s", "o", "s"];
      // this.unlockPattern = ["ArrowUp", "ArrowUp", "ArrowUp"];
      this.unlockPattern = this.unlockPattern.map((string) => string.toLowerCase());
      this.currentUnlockPosition = this.currentUnlockPosition || 0;
      this.currentUnlockAtempt = this.currentUnlockAtempt || 0;
      const keyPressed = event.key.toLowerCase();

      //* If the key isn't in the pattern, or isn't the current key in the pattern, reset
      if (this.unlockPattern.indexOf(keyPressed) < 0 || keyPressed !== this.unlockPattern[this.currentUnlockPosition]) {
        this.log_function("wrong_key");
        this.currentUnlockPosition = 0;
        this.currentUnlockAtempt++;

        if (this.currentUnlockAtempt >= 10) {
          this.removeUnlockEventListener();
        }

        return;
      } else {
        this.log_function("correct_key");
        //* Update how much of the pattern is complete
        this.currentUnlockPosition++;
        //* If complete, alert and reset
        if (this.unlockPattern.length === this.currentUnlockPosition) {
          this.log_function("unlocked");
          this.currentUnlockPosition = 0;
          this.open();
        }
      }
    } else {
      this.log_error_function("unlockTool", "event.key.toLowerCase is not defined");
    }
  }

  addUnlockEventListener() {
    this.log_function("addUnlockEventListener");

    window.addEventListener("keydown", this.boundUnlockTool, false);
  }

  removeUnlockEventListener() {
    this.log_function("removeUnlockEventListener");

    window.removeEventListener("keydown", this.boundUnlockTool);
  }

  fixHeight() {
    this.log_function("fixHeight");

    const height = this.element.offsetHeight;
    this.element.style.marginTop = `-${height}px`;
  }

  disconnect(){
    this.log_function("disconnect");

    if (this.heightObserver) this.heightObserver.disconnect();
  }

  get is_open() {
    this.log_function("get is_open");

    if (typeof localStorage === "undefined") return false;
    return localStorage.getItem("development_tools_open") || false;
  }
}
