import ApplicationController from "../../application_controller.js";
import Modal from "bootstrap/js/dist/modal";

export default class extends ApplicationController  {
  static values = {
    id: String,
  };

  connect() {
    this.log_function("connect");

    this.modalElement = document.getElementById(this.idValue);
    this.modalInstance = Modal.getOrCreateInstance(this.modalElement);
  }

  show(){
    this.log_function("show");

    this.modalInstance.show();
  }

  hide(){
    this.log_function("hide");

    this.modalInstance.hide();
  }

  toggle(){
    this.log_function("toggle");

    this.modalInstance.hide();
  }

  update() {
    this.log_function("update");

    this.modalInstance.handleUpdate();
  }

  dispose() {
    this.log_function("dispose");

    this.modalInstance.handleUpdate();
  }

  disconnect(){
    this.log_function("disconnect");

    this.dispose();
  }
}
