import ApplicationController from "../../../application_controller.js";
import ahoy from "ahoy.js";

export default class extends ApplicationController {
  static values = {
    type: String,
    action: String,
    page: String,
    location: String,
    name: String,
    value: String,
    data: Object,
    trigger: { type: String, default: "click" },
    once: { type: Boolean, default: true },
  };

  connect() {
    this.log_function("connect");

    switch (this.triggerValue) {
      case "click":
        this.onTriggerEvent = this.triggerEvent.bind(this);
        this.element.addEventListener("click", this.onTriggerEvent, { once: this.onceValue });
        break;
      case "hover":
        this.onTriggerEvent = this.triggerEvent.bind(this);
        this.element.addEventListener("mouseover", this.onTriggerEvent, { once: this.onceValue });
        break;
      case "connect":
        this.triggerEvent();
        break;
      default:
        this.log_error_function("connect", `Event trigger "${this.triggerValue}" doesn't exist.`);
        break;
    }
  }

  triggerEvent(){
    this.log_function("triggerEvent");

    if((this.hasTypeValue && this.typeValue !== "") && (this.hasActionValue && this.actionValue !== "") && (this.hasPageValue && this.pageValue !== "")){
      this.log_function("triggerEvent", "analytics/ahoy -- dispatching event");
      let eventDetails = {
        type: this.typeValue,
        action: this.actionValue,
        page: this.pageValue,
        ...this.dataValue,
      };

      if(this.hasNameValue && this.nameValue !== ""){
        eventDetails["name"] = this.nameValue;
      }

      if(this.hasLocationValue && this.locationValue !== ""){
        eventDetails["location"] = this.locationValue;
      }

      if(this.hasValueValue && this.valueValue !== ""){
        eventDetails["value"] = this.valueValue;
      }

      this.log_function("triggerEvent", "analytics/ahoy", eventDetails);

      this.dispatch("ahoy_trackEvent", {
        detail: eventDetails,
        prefix: false,
      });

    } else {
      this.log_error_function("analytics", "You haven't provided require event values.");
      return false;
    }
  }

  disconnect(){
    this.log_function("disconnect");

    switch (this.triggerValue) {
      case "click":
        this.element.removeEventListener("click", this.onTriggerEvent);
        break;
      case "hover":
        this.element.removeEventListener("mouseover", this.onTriggerEvent);
        break;
    }
  }
}
